<template>
  <div class="login">
    <div class="page">
      <div class="header">
        <div class="header-logo">
          <!-- <img src="../../assets/images/tubiao/yezai_logo.png" alt="" /> -->
          <!-- <img src="../../assets/images/tubiao/yezai_logo_A.png" alt="" /> -->
          <img src="../../assets/images/tubiao/yezai_logo_B.png" alt="" />
        </div>
        <div class="header-btn">
          单身中还没账号？<router-link to="/register" style="text-decorate: border-bottom">立即注册</router-link>
        </div>
      </div>
    </div>

    <!-- 主要信息 -->
    <div class="main">
      <div class="main-img">
        <img :src="banner" alt="" />
      </div>
      <!-- 登录框 -->
      <div class="main-login" v-if="iSLogin">
        <h3>登录也在网</h3>
        <div class="line"></div>
        <el-input v-model="phone" maxlength="11" placeholder="请输入手机号"></el-input>

        <!-- <el-input
          v-model="obj.mobile"
          placeholder="请输入手机号"
          maxlength="13"
          @blur="paxNameBlur"
          v-on:input="validateMobile"
        ></el-input> -->

        <p class="yzm-phone" v-show="Yzmphone">请输入手机号</p>
        <el-input v-model="pw" maxlength="20" placeholder="请输入密码" type="password"></el-input>
        <p class="yzm-pw" v-show="Yzmpw">密码为空</p>
        <div class="Forget-pw">
          <el-checkbox v-model="checked">下次自动登录</el-checkbox>
          <p><router-link to="/forgotpassword">忘记密码？</router-link></p>
        </div>

        <p class="error" v-show="error">账号或密码错误，请检查后重试</p>

        <div class="Btn-login" @click="LoginBtn">立即登录</div>
        <!-- 极验 -->
        <div id="captcha_register"></div>

        <div class="zhuce">
          <router-link to="/register">免费注册</router-link>
        </div>
      </div>

      <!-- 验证码框 -->
      <div class="main-login iSLogin" v-else>
        <h3>登录验证</h3>
        <div class="line"></div>
        <p class="iSLogin-phone">
          验证码已通过短信发送至<span>{{ phone | mobileFilter }}</span>
        </p>
        <div class="iSLogin-yzm">
          <el-input v-model="Yzm" maxlength="6" placeholder="请输入验证码"></el-input>
          <el-button ref="btn" :disabled="disabled" :plain="true" @click="sendcode">
            {{ btntxt }}
          </el-button>
          <p class="Btn-loginLL-yzm" v-if="iSyzm">验证码输入有误</p>
        </div>

        <div class="Btn-loginLL" @click="LoginBox">确定</div>
        <p class="iSLogin-s">如你已更换手机号，请致电4008-234-521</p>
      </div>
    </div>

    <!-- 广告 -->
    <div class="app-wrapper">
      <div class="app">
        <h3>也在APP，多种方式助你成功寻爱</h3>
        <div class="app-box">
          <div class="app-left">
            <ul>
              <li>
                <img src="../../assets/images/login/Group.png" alt="" />
                <div class="right-title">
                  <p>也在视频</p>
                  <p>面对面相亲，真诚高效</p>
                </div>
              </li>
              <li>
                <img src="../../assets/images/login/dongtai.png" alt="" />
                <div class="right-title">
                  <p>动态</p>
                  <p>走进TA的生活圈</p>
                </div>
              </li>
              <li>
                <img src="../../assets/images/login/yuyin.png" alt="" />
                <div class="right-title">
                  <p>精准推荐</p>
                  <p>大数据算法精准推荐</p>
                </div>
              </li>
              <li>
                <img src="../../assets/images/login/yiliaohangyedeICON.png" alt="" />
                <div class="right-title">
                  <p>心有灵析</p>
                  <p>剖析内心，让三观更匹配</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="app-right" style="">
            <div class="Qrcode">
              <img src="../../assets/images/logo/logo.png" alt="" />
            </div>
            <p>下载APP 体验更多功能</p>
            <!-- <div class="app-jiao"></div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>


<script>
import Footer from "@/components/layout/footer";
import { loginAuth, infocur, updateTerritory } from "@/api/login/login.js"; //获取用户信息,登录
import local from "@/api/common/local.js"; //存储
import { sendSms, validSms, Register } from "@/api/member/member.js"; //发送短信,短信登录,极验接口
// import { sendSms, validSms } from "@/api/member/member.js"; //发送短信,短信登录,极验接口
// import axios from "axios";
export default {
  //处理手机号
  filters: {
    mobileFilter(val) {
      let reg = /^(.{3}).*(.{4})$/;
      return val.replace(reg, "$1****$2");
    },
  },
  metaInfo: {
    title: "也在网-婚恋安全登录界面专区",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      }, {
        name: "keywords",
        content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
      }, {
        name: "description",
        content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
      }
    ],
  },
  data() {
    return {
      phone: "",
      pw: "",
      error: false,
      checked: false,
      Yzmphone: false,
      Yzmpw: false,
      Yzmerror: false,
      banner: require("@/assets/images/banner/banner_b.png"),
      mobileLL: "",
      iSLogin: true,
      Yzm: "",
      btntxt: "",
      disabled: true,
      num: 30,
      obj: {
        mobile: "",
      },
      iSyzm: false,
    };
  },
  components: {
    Footer,
  },
  created() {
    // history.pushState(null, null, document.URL);
    // window.onpopstate = function () {
    //   history.pushState(null, null, document.URL);
    // };
    this.fn(); //初始化极验
    this.tz();
  },
  // mounted() {
  //   this.fn(); //初始化极验
  //   this.tz();
  // },
  methods: {
    async tz() {
      let Data = local.get("access_token");
      if (Data) {
        const { code } = await infocur(Data);
        if (code == 0) {
          this.$router.push({
            path: "/n/myyezai",
          });
        }
      }
      // let Data = local.get("access_token");
      // const { code } = await infocur(Data);
      // if (code == 0) {
      //   this.$router.push({
      //     path: "/n/myyezai",
      //   });
      // }
      // //查看当前用户是否临时存储
      // let a = local.get("user");
      // if (a == null) {
      //   console.log(" ");
      // } else {
      //   // console.log('00',a);
      //   let Data = {
      //     loginMethod: "P",
      //     loginSource: 0,
      //     loginType: "M",
      //     account: a.account,
      //     // password: this.$md5(this.pw),
      //     password: a.password,
      //   };
      //   const { code, data } = await loginAuth(Data);
      //   if (code == 0) {
      //     local.set("access_token", data.access_token); //存储令牌
      //     this.$router.push({
      //       path: "/n/myyezai",
      //     });
      //   }
      // }
    },

    async LoginBtn() {
      // console.log("00", this.obj.mobile, "01", this.phone);
      if (this.phone == "") {
        this.Yzmphone = true;
      } else {
        this.Yzmphone = false;
      }
      if (this.pw == "") {
        this.Yzmpw = true;
      } else {
        this.Yzmpw = false;
      }

      // 调接口跳转主页面
      if (!(this.phone == "" || this.pw == "")) {
        // gt	字符串	验证 id，极验后台申请得到
        // challenge	字符串	验证流水号，服务端 SDK 向极验服务器申请得到
        // offline	布尔	极验API服务器是否宕机
        // new_captcha
        // 极验
        // 会员登录
        let Data = {
          loginMethod: "P",
          loginSource: 0,
          loginType: "M",
          account: this.phone,
          // password: this.$md5(this.pw),
          password: this.pw,
        };
        const { code, data } = await loginAuth(Data);
        if (code == 0) {
          console.log("跳转", code, data);
          this.error = false;
          // local.set("access_token", data.access_token); //存储令牌
          // this.iSLogin = false;
          // this.$router.push({
          //   path: "/n/myyezai",
          // });
          // return true
          //调用极验方法
          let geetest_data = this.captcha_obj.getValidate();
          if (geetest_data == undefined) {
            //没有通过验证
            this.captcha_obj.verify(); //调起极验进行验证
            return;
          }
          //通过验证，把验证后的三个参数放入payload post去短信验证码接口，极验验证码需要后端二次验证
          let payload = {};
          payload.geetest_challenge = geetest_data.geetest_challenge;
          payload.geetest_seccode = geetest_data.geetest_seccode;
          payload.geetest_validate = geetest_data.geetest_validate;
          //手机号
          payload.phone = "";
          //往验证码接口发起请求 ajax或者axios等等
          // console.log('11')
          this.iSLogin = false;
          this.Btn();
        } else {
          this.error = true;
          // return false
        }
      }

      let a = 0;

      return a;
    },
    async fn() {
      let handler = function (captcha_obj, vm) {
        captcha_obj.appendTo("#captcha_register");
        captcha_obj
          .onReady(function () {
            //初始化完成代码
            vm.captcha_obj = captcha_obj; //把这个obj放入了vue实例里管理
            // vm.captcha_obj.appendTo("#captcha_register");
          })
          .onSuccess(function () {
            //获取验证完成的三个参数调用captcha_obj.getValidate()
            vm.captcha_obj = captcha_obj;
            // console.log("0000000");
            //完成验证再次调用发送验证码函数，即可通过检测发送请求
            // vm.getSms();
            vm.LoginBtn();
            vm.Sms();
          })
          .onError(function () {
            captcha_obj.reset();
          });
      };

      const D = await Register();

      //测试
      // const D = axios
      //   .get("http://119.23.65.114:28080/geetest/register")
      //   .then();
      //专用
      // eslint-disable-next-line no-undef
      initGeetest(
        {
          gt: D.gt,
          challenge: D.challenge,
          new_captcha: D.new_captcha, // 用于宕机时表示是新验证码的宕机
          offline: !D.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          product: "bind", // 产品形式，包括：float，popup ,
          width: "100%",
        },
        handler,
        this
      );
    },
    getSms() {
      //调用参数检查（手机号码），参数检查自己写
      // console.log('0000',this.LoginBtn());
      if (this.LoginBtn()) {
        let geetest_data = this.captcha_obj.getValidate();
        if (geetest_data == undefined) {
          //没有通过验证
          this.captcha_obj.verify(); //调起极验进行验证
          return;
        }
        //通过验证，把验证后的三个参数放入payload post去短信验证码接口，极验验证码需要后端二次验证
        let payload = {};
        payload.geetest_challenge = geetest_data.geetest_challenge;
        payload.geetest_seccode = geetest_data.geetest_seccode;
        payload.geetest_validate = geetest_data.geetest_validate;
        //手机号
        payload.phone = "";
        //往验证码接口发起请求 ajax或者axios等等
        // console.log('11')
        this.iSLogin = false;
      }
    },
    chechPara() {
      return false;
    },
    async LoginBox() {
      console.log("数据");
      if (this.Yzm !== "") {
        let a = {
          code: this.Yzm,
        };
        const { code } = await validSms(a);
        if (code == 0) {
          let Data = {
            loginMethod: "P",
            loginSource: 0,
            loginType: "M",
            account: this.phone,
            // password: this.$md5(this.pw),
            password: this.pw,
          };
          const D = await loginAuth(Data);
          if (D.code == 0) {
            local.set("access_token", D.data.access_token); //存储令牌
            /**
             * 上报IP属地
             */
            updateTerritory();
            let user = {
              account: this.phone,
              //  password: this.$md5(this.pw),
              password: this.pw,
            };
            if (this.checked) {
              local.set("user", user);
            }

            this.$router.push({
              path: "/n/myyezai",
            });
            let Data = local.get("access_token");
            const { code, data } = await infocur(Data);
            if (code == 0) {
              local.set("userId", data.user.id);
            }
          }
        } else {
          // this.iSyzm = true;
          this.$message({
            message: "验证码输入有误",
            type: "error",
            offset: 100,
          });
        }
      } else {
        this.$message({
          message: "验证码不能为空",
          type: "error",
          offset: 100,
        });
      }
    },
    //发送短信
    async Sms() {
      let a = {
        phonenumber: this.phone,
        busType: 3,
      };
      const { code, data } = await sendSms(a);
      if (code == 0) {
        console.log("数据", data);
        // if (code == 0) {
        this.$message({
          message: "发送验证码成功",
          type: "success",
          offset: 100,
        });
        // }
      } else {
        this.$message({
          message: "发送验证码失败",
          type: "error",
          offset: 100,
        });
      }
    },
    async Btn() {
      const _that = this;
      if (this.num > 0) {
        this.num--;
        console.log("_", this.num);
        this.btntxt = `已发送(${this.num}s)`;
        // setTimeout(this.Btn, 1000);
        const tim = setTimeout(this.Btn, 1000);
        /**
         * 清除定时器
         */
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once("hook:beforeDestroy", () => {
          clearInterval(tim);
        });
      } else {
        this.btntxt = "重新发送";
        _that.$refs.btn.$el.style.backgroundColor = "#FD686EFF";
        _that.$refs.btn.$el.style.color = "#ffffff";
        this.disabled = false;
      }
    },
    sendcode() {
      this.num = 30;
      this.disabled = true;
      const _that = this;
      _that.$refs.btn.$el.style.backgroundColor = "#fafafaff";
      _that.$refs.btn.$el.style.color = "#ADADADFF";
      this.Btn();
      this.Sms();
    },
    validateMobile(val) {
      if (val.length < 13) {
        if (val.length > 3 && val.length < 7) {
          val = val
            .replace(/\s/g, " ")
            .replace(/[^\d]/g, " ")
            .replace(/(\d{3})(?=\d)/g, "$1 ");
        } else if (val.length >= 7) {
          val = val
            .replace(/\s/g, " ")
            .replace(/[^\d]/g, " ")
            .replace(/(\d{4})(?=\d)/g, "$1 ");
        }
        this.$set(this.obj, "mobile", val);
      }
    },
    paxNameBlur() {
      this.phone = this.obj.mobile.replace(/\s/g, "");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
body,
html,
template {
  width: 100%;
}

.page {
  width: 100%;
  position: fixed;
  background-color: #fd686eff;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  top: 0px;
  left: 0px;
  z-index: 9999;

  .header {
    min-width: 1200px;
    height: 80px;
    background-color: #fd686e;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-logo {
      width: 136px;
      // height: 53px;
      // background-color: aqua;
    }

    .header-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;

      a {
        text-decoration: underline !important;
        color: #ffffff;
      }
    }
  }
}

.main {
  box-sizing: border-box;
  min-width: 1200px;
  position: relative;

  .main-img {
    width: 100%;
    // height: 770px;
    overflow: hidden;
    position: relative;

    background-position: center center;

    img {
      width: 100%;
      height: 100%;

      @media screen {
        min-width: 1920px;
      }
    }
  }

  .main-login {
    position: absolute;
    top: 150px;
    right: 370px;
    width: 370px;
    height: 370px;
    padding: 30px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    h3 {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      text-align: center;
    }

    .line {
      width: 370px;
      margin-top: 22px;
      border-bottom: 1px solid #ebebeb;
      // box-sizing: border-box;
      // border: 1px solid #ebebeb;
    }

    .yzm-phone {
      position: absolute;
      top: 150px;
      left: 50px;
      color: #fd686e;
    }

    .yzm-pw {
      position: absolute;
      top: 216px;
      left: 50px;
      color: #fd686e;
    }

    .error {
      position: absolute;
      bottom: 140px;
      left: 50px;
      color: #fd686e;
    }

    /deep/ .el-input {
      width: 320px;
      height: 42px;
      margin-top: 24px;
    }

    ::-webkit-input-placeholder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #adadad;
      line-height: 20px;
    }

    /deep/ .el-input__inner {
      background: #f5f5f5ff;
      border: none;
      outline: none;
      width: 290px;
      margin-left: 10px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      line-height: 20px;
    }

    /deep/ .el-checkbox__label {
      color: #777 !important;

      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #adadadff;
    }

    .Forget-pw {
      display: flex;
      width: 290px;
      justify-content: space-between;
      margin-top: 34px;
      margin-bottom: 20px;
      font-size: 16px;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        cursor: pointer;

        a {
          color: #777;
        }
      }
    }
  }

  .iSLogin {
    .iSLogin-phone {
      margin-top: 40px;
      margin-bottom: 30px;
      font-size: 16px;
    }

    .iSLogin-yzm {
      display: flex;
      width: 290px;
      justify-content: space-between;
      box-sizing: border-box;
      position: relative;

      /deep/ .el-input {
        width: 164px !important;
        height: 40px !important;
        // padding: 10px 20px !important;
        margin: 0px !important;
      }

      /deep/ .el-input__inner {
        width: 164px !important;
        height: 40px !important;
        padding: 10px 20px !important;
        margin-left: 0px !important;
      }

      /deep/ .el-button {
        width: 110px;
        height: 40px;
      }

      .Btn-loginLL-yzm {
        position: absolute;
        top: 50px;
        left: 10px;
        font-size: 12px;
        color: red;
      }
    }

    .Btn-loginLL {
      // margin-top: 70px;
      margin-top: 50px;
      cursor: pointer;
      width: 290px;
      background: #fd686e;
      border-radius: 6px;
      font-size: 16px;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-bottom: 20px;
    }

    .iSLogin-s {
      margin-bottom: 20px;
      font-size: 12px;
    }
  }

  .Btn-login {
    cursor: pointer;
    width: 290px;
    background: #fd686e;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }

  .zhuce {
    margin-top: 12px;

    a {
      color: #fd686e;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fd686e;
    }
  }
}

.app-wrapper {
  height: 562px;
  min-width: 1200px;
  background: #fafafaff;
  display: flex;
  justify-content: center;

  .app {

    // width: 1200px;
    h3 {
      margin: 80px 0 70px;
      text-align: center;
      font-size: 28px;
      color: #555555;
    }

    .app-box {
      display: flex;
      padding: 0 100px;
      box-sizing: border-box;

      .app-left {
        ul {
          display: flex;
          flex-wrap: wrap;
          width: 600px;
          margin-top: 46px;

          li:nth-child(3),
          li:nth-child(4) {
            margin-top: 68px;
          }

          li:nth-child(2),
          li:nth-child(4) {
            margin-left: 40px;
          }

          li {
            display: flex;
            align-items: center;
            min-width: 280px;

            .right-title {
              margin-left: 20px;

              p:nth-child(1) {
                // font-size: 24px;
                font-size: 20px;
                font-weight: bold;
                color: #333;
                // font-weight: 500;
                // color: #fd686e;
              }

              p:nth-child(2) {
                // font-size: 18px;
                margin-top: 8px;
                font-size: 16px;
                color: #666;
                // font-weight: 400;
                // color: #555555;
              }
            }
          }
        }
      }

      //       .app-right::after {
      //         width: 0;
      //         height: 0;
      //         border: solid transparent;
      //         position: absolute;
      //         left: 0px;
      //         top: 0px;
      //         // background-color: #fafafa;
      //         //  border-top:100px solid yellow;
      //         //  border-right:50px solid yellow;
      //         //  border-top: 50px solid yellow;
      //         //  border-bottom: 100px solid yellow;
      //         // border-left:50px solid #fff;
      // // width:10px;
      // // height:10px;
      // // border:10px solid #000;
      // // border-left-color:orange;
      //         /*该属性一定要有*/
      //         content: "";
      //       }
      .app-right {
        width: 270px;
        height: 298px;
        background: #ffffff;
        margin-left: 100px;
        // background: ;
        background: url("../../assets/images/logo/bgcJ.png") no-repeat;
        // background-image: ;
        // border-radius: 80px 12px 12px 12px;
        // border-radius: 80px 12px 12px 12px;
        box-sizing: border-box;
        padding-top: 35px;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;

        .Qrcode {
          width: 165px;
          height: 165px;
          background: url("../../assets/images/login/bgc.png") no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 130px;
            height: 130px;
          }

          // background: url("../../assets/images/logo/logo.png") no-repeat;
          // ../../../assets/images/logo/logo.png
        }

        p {
          margin-top: 30px;
          font-size: 18px;
          font-weight: 400;
          color: #fd686e;
          line-height: 25px;
        }

        // .app-jiao{
        //   position: absolute;
        //   top: 0px;
        //   right: 0px;
        //   width: 54px;
        //   height: 50px;
        //   background-color: #FAFAFA;
        //   // border-radius: 50% 50%;
        //   border-radius: 0px 0px 0px 0px;
        // }
      }
    }
  }
}
</style>
